import { useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AuthContext } from '../utils/constants';
import { FaEnvelope, FaTools, FaDownload, FaUpload } from "react-icons/fa";
import { FaBell, FaCheck, FaListCheck, FaCubes, FaFileLines, FaGauge, FaLayerGroup, FaTriangleExclamation, FaUser } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
import { LuFileSearch2 } from "react-icons/lu";
import { Image } from 'react-bootstrap';

function Alerts() {
  return(
    <div className="dropdown message-dropdown">
      <Link className="nav-link text-muted" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
        <FaBell />
      </Link>
      <ul className="dropdown-menu message-dropdown-menu dropdown-menu-end shadow">
        <h6 className="mb-2 dropdown-header border-bottom">Alert Center</h6>
        <li>
          <Link className="dropdown-item d-flex align-items-center dropdown_item-1" to="#">
            <div className="me-3">
              <div className="icon-circle bg-warning text-light">
                <FaTriangleExclamation />
              </div>
            </div>
            <div className="message-description">
                <div className="small text-secondary">December 2, 2019</div>
                <span className="text-wrap text-muted fw-bold">Spending Alert: We've noticed unusually high spending for your account.</span>
            </div>
          </Link>
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center dropdown_item-2" to="#">
            <div className="me-3">
                <div className="icon-circle bg-success text-light">
                    <FaCheck />
                </div>
            </div>
            <div className="message-description">
                <div className="small text-secondary">December 12, 2021</div>
                <span className="text-wrap text-muted">Your application for the change request has been approved.</span>
            </div>
          </Link>
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center dropdown_item-3" to="#">
            <div className="me-3">
                <div className="icon-circle bg-primary text-light">
                    <FaFileLines />
                </div>
            </div>
            <div className="message-description">
                <div className="small text-secondary">December 12, 2021</div>
                <span className="text-wrap text-muted">A new monthly report is ready to download!</span>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
}

function Messages() {
  return (
    <div className="dropdown message-dropdown">
      <Link className="nav-link text-muted" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
        <FaEnvelope />
      </Link>
      <ul className="dropdown-menu message-dropdown-menu dropdown-menu-end shadow">
        <h6 className="mb-2 dropdown-header border-bottom">Message Center</h6>
        <li>
            <Link className="dropdown-item d-flex align-items-center dropdown_item-1" to="#">
              <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="/img/girl_admin.png" alt="..." />
                  <div className="status-indicator bg-success"></div>
              </div>
              <div className="fw-bold message-description">
                  <div className="text-truncate text-muted">Hi there! I am wondering if you can help me with a
                      problem I've been having.</div>
                  <div className="small text-secondary">Emily Fowler · 58m</div>
              </div>
            </Link>
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center dropdown_item-2" to="#">
              <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="/img/boy_with_specs.png"
                      alt="..." />
                  <div className="status-indicator"></div>
              </div>
              <div className="message-description">
                  <div className="text-truncate text-muted">I have the photos that you ordered last month, how
                      would you like them sent to you?</div>
                  <div className="small text-secondary">Jae Chun · 1d</div>
              </div>
          </Link>
        </li>
        <li>
          <Link className="dropdown-item d-flex align-items-center dropdown_item-3" to="#">
              <div className="dropdown-list-image me-3">
                  <img className="rounded-circle" src="/img/girl_user_black.png"
                      alt="..." />
                  <div className="status-indicator bg-warning"></div>
              </div>
              <div className="message-description">
                  <div className="text-truncate text-muted">Last month's report looks great, I am very happy with
                      the progress so far, keep up the good work!</div>
                  <div className="small text-secondary">Morgan Alvarez · 2d</div>
              </div>
          </Link>
        </li>
      </ul>
    </div>
  );
}

function TopNav() {
  const { signOut } = useContext(AuthContext);
  let loginUser = sessionStorage.getItem("userName");
  
  return(
    <div id="topnav">
      <nav className="navbar fixed-top navbar-expand-md bg-white text-dark shadow-sm ps-2">
        <div className="container-fluid">
          <Image src="./logo.jpg" className="me-2 mb-1" height="30" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav me-auto">
              <li className="nav-item p-1">
                <NavLink to="/" className="d-flex align-items-center nav-link">
                  <FaGauge />                  
                  <span className="ms-1 nav-link-text">Dashboard </span>
                </NavLink>
              </li>
              <li className="nav-item p-1">
                <NavLink to="/outwards" className="d-flex align-items-center nav-link">                  
                  <FaUpload />                  
                  <span className="ms-1 nav-link-text">Outwards </span>
                </NavLink>
              </li>
              <li className="nav-item p-1">
                <NavLink to="/inwards" className="d-flex align-items-center nav-link">
                  <FaDownload />
                  <span className="ms-1 nav-link-text">Inwards</span>
                </NavLink>
              </li>
              <li className="nav-item p-1">
                <NavLink to="/asset-mapping" className="d-flex align-items-center nav-link">
                  <FaLayerGroup />
                  <span className="ms-1 nav-link-text">Asset Mapping</span>
                </NavLink>
              </li>
              {/*
                <li className="nav-item p-1">
                  <NavLink to="/order-status" className="d-flex align-items-center nav-link">
                    <FaListCheck />
                    <span className="ms-1 nav-link-text">Order Status </span>
                  </NavLink>
                </li>              
                <li className="nav-item p-1">
                  <NavLink to="/stock-information" className="d-flex align-items-center nav-link">
                    <FaLayerGroup />
                    <span className="ms-1 nav-link-text">Stock Information</span>
                  </NavLink>
                </li>              
              */}
            </ul>
            {/*
            <Alerts />
            <Messages />
            */}
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {loginUser} <FaUser />
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">                  
                  <li>
                    <button className="dropdown-item dropdown_item-3 pe-1" onClick={signOut}>
                      Logout <TbLogout />
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

const OffCanvas = (props)=> {

  async function scrollChatPage(){
    const El = await document.getElementById('chatArea');
    if(El) {
      const scrollToBottom = (node) => {
      	node.scrollTop = node.scrollHeight;
      }
      scrollToBottom(El);
    }
  }

  useEffect(()=>{
    scrollChatPage();
  },[])

  return(
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header border-bottom">
        <h5 className="offcanvas-title" id="offcanvasRightLabel">{props.header}</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div id="chatArea" className="offcanvas-body mb-1">
        {props.body}
      </div>
      <div className="sticky-bottom">
        {props.footer}
      </div>
    </div>
  );
}

export { TopNav, OffCanvas };
